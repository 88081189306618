import React, { useState } from 'react';
import { addProduct } from './apiService'; // Import the API service
import Header from '../Header';
import Footer from '../Footer';
import {
    Grid,
    TextField,
    Button,
    Typography,
    Paper,
    Card,
    CardContent,
    CardMedia,
    Box
} from '@mui/material';

const AddProduct = ({ onProductAdded = () => {} }) => {
    const [newProduct, setNewProduct] = useState({
        code: '',
        type: '',
        color: '',
        image: '',
        fabric: '',
        details: [], // Initialize details as an array
        category: '',
        washcare: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State for error messages

    // Handle form changes
    const handleNewProductChange = (e) => {
        setNewProduct({
            ...newProduct,
            [e.target.name]: e.target.value
        });
    };

    const handleDetailsChange = (e) => {
        const value = e.target.value.split('\n').map(line => line.split(',').map(item => item.trim()));
        setNewProduct({
            ...newProduct,
            details: value
        });
    };

    // Handle adding product
    const handleAddProduct = async () => {
        try {
            const productData = {
                ...newProduct,
                color: newProduct.color.split(',').map((c) => c.trim()),
                image: newProduct.image.split(',').map((i) => i.trim()),
                fabric: newProduct.fabric.split(',').map((f) => f.trim()),
                washcare: newProduct.washcare.split(',').map((w) => w.trim())
            };
            await addProduct(productData); // POST request using apiService

            setSuccessMessage('Product added successfully!');
            setErrorMessage(''); // Clear any previous error message
            onProductAdded(); // Trigger parent component's refresh if necessary

            // Reset form
            setNewProduct({
                code: '',
                type: '',
                color: [],
                image: '',
                fabric: [],
                details: [],
                category: '',
                washcare: []
            });
        } catch (error) {
            setErrorMessage('Error adding product. Please try again.'); // Display error message
            console.error('Error adding product:', error);
        }
    };

    return (
        <>
        <Header />
        <div style={{ paddingTop: '10px', paddingBottom: '10px', overflowY: 'auto', height: '87vh' }}>
            <Grid container spacing={2} padding={2} >
                {/* Left Grid - Form */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Add New Product
                        </Typography>
                        <TextField
                            fullWidth
                            label="Code"
                            name="code"
                            value={newProduct.code}
                            onChange={handleNewProductChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Type"
                            name="type"
                            value={newProduct.type}
                            onChange={handleNewProductChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Image URL (comma separated)"
                            name="image"
                            value={newProduct.image}
                            onChange={handleNewProductChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Details (use commas to separate values, one set per line)"
                            multiline
                            rows={4}
                            value={newProduct.details.map(item => item.join(', ')).join('\n')}
                            onChange={handleDetailsChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Color (comma separated)"
                            name="color"
                            value={newProduct.color}
                            onChange={handleNewProductChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Fabric (comma separated)"
                            name="fabric"
                            value={newProduct.fabric}
                            onChange={handleNewProductChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Category"
                            name="category"
                            value={newProduct.category}
                            onChange={handleNewProductChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Washcare (comma separated)"
                            name="washcare"
                            value={newProduct.washcare}
                            onChange={handleNewProductChange}
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddProduct}
                            fullWidth
                            sx={{ marginTop: 2 }}
                        >
                            Add Product
                        </Button>

                        {/* Success and Error messages */}
                        {successMessage && <Typography color="green">{successMessage}</Typography>}
                        {errorMessage && <Typography color="red">{errorMessage}</Typography>}
                    </Paper>
                </Grid>

                {/* Right Grid - Product Preview */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Product Preview
                        </Typography>
                        <Card>
                            {/* Image preview */}
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                {newProduct.image.split(',').map((imgUrl, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        image={imgUrl}
                                        alt={`product-image-${index}`}
                                        sx={{ width: 150, height: 250, objectFit: 'scale-down', margin: 1 }}
                                    />
                                ))}
                            </Box>
                            <CardContent>
                                <Typography variant="h6">Code: {newProduct.code}</Typography>
                                <Typography variant="body1">Type: {newProduct.type}</Typography>
                                <Typography variant="body1">Category: {newProduct.category}</Typography>
                                <Typography variant="body1" >Color: {newProduct.color}</Typography>
                                <div style={{ backgroundColor: newProduct.color, width: '20px', height: '20px' }}></div>
                                <Typography variant="body1">Fabric: {newProduct.fabric}</Typography>
                                <Typography variant="body1">Washcare: {newProduct.washcare}</Typography>
                                <Typography variant="body2">
                                    Details:
                                    {newProduct.details.length > 0 && (
                                        <ul>
                                            {newProduct.details.map((detail, index) => (
                                                <li key={index}>{detail.join(', ')}</li>
                                            ))}
                                        </ul>
                                    )}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
            </Grid>
        </div>
        <Footer />
    </>
    );
};

export default AddProduct;
